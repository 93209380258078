<template>
  <div class="w-full bg-vdv-grey">
    <div
      class="grid items-center max-w-screen-xl grid-cols-4 mx-auto justify-items-center md:grid-cols-12 gap-sm md:gap-md"
    >
      <div
        class="col-span-2 col-start-2 md:col-start-3 pt-md md:py-md px-sm pl-xl md:pl-0"
      >
        <img
          v-if="isOfficeList"
          src="@/assets/images/office-no-entries.svg"
          alt="calender svg"
          loading="lazy"
        />
        <img
          v-else
          src="@/assets/images/calender.svg"
          alt="calender svg"
          loading="lazy"
        />
      </div>
      <div
        class="col-span-4 text-center md:col-span-6 md:col-start-5 md:pl-xl px-md md:px-xl pb-md md:pb-0"
      >
        {{ getNoEntriesText() }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const { t } = useTrans();

const { currentRoute } = useRouter();

const isOfficeList = currentRoute.value.path == '/niederlassung/praxisboerse';

function getNoEntriesText(): string {
  if (isOfficeList) {
    return t('office.noEntries');
  }

  const translation =
    currentRoute.value.path == '/akademie/trainings-events'
      ? t('event.noEntries')
      : t('post.noEntries');
  return translation;
}
</script>
